.outermostContainer {

  @media only screen and (max-width: 1200px) {
    padding: 0 32px;
  }
  
  h2 {
    text-align: left;
    font-size: large;
    margin-bottom: 2px;
    position: relative;
  }
  h3 {
    font-size: 15px;
    text-align: left;
    padding-bottom: 2rem;
  }
}

ul {
  list-style: none;
  padding-left: 0px;
  
  .otherDatabase {
    text-align: left;
    margin-left: 0px;
    padding: 4px 8px;
    &:nth-child(odd) {
      background-color: #f3f4f7;
    }
    span {
      float: right;
      word-wrap: break-word;
      margin-left: auto;
    }
  }
}

.referenceDatabase {
  text-align: left;
  margin-left: 0px;
  padding: 4px 8px;
  p {
    padding-top: 0px;
    margin-top: 0px;
    background-color: #fff;
    font-weight: bold;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  div {
    flex: 1;
  }
  span {
    cursor: pointer;
  }
  div:first-of-type {
    > span {
      float: left;
    }
  }
  div:last-of-type {
    > span {
      float: right;
    }
  }
  color: #2a4b6b;
  > a {
    color: #099bd9;
    text-decoration: none;
  }

  @media only screen and (max-width: 1200px) {}
}

.copyright {
  display: none !important;
  text-align: center;
  margin: auto;
}

.containerLogoName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  div {
    flex: 1;
  }
  div:first-of-type {
    > * {
      float: left;
      margin-left: -16px;
      @media only screen and (max-width: 1200px) {
        margin-left: 0px;
      }
      @media print {
        margin-left: 0px;
      }
    }
  }
  div:last-of-type {
    > * {
      max-width: 80px;
      height: auto;
      float: right;
      display: none !important;
      @media print {
        display: inline-block !important;
      }
    }
  }
}

@media print {
  .copyright {
    display: block !important;
  }
  
  footer,
  header,
  .legend_information,
  .actions
  {
    display: none !important;
  }
}
