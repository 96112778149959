.singleResultInfo {
  display: flex;
  flex-direction: row;
  margin-left: 0px;

  ul {
    list-style-type: none;
    margin-left: 0px;
    margin-right: 50px;
    padding-left: 0px;
    text-align: left;
    li {
      margin-left: 0px;
      padding-left: 0px;
      color: black;
      width: max- content;
    }
    span {
      font-weight: bold;
      padding-left: 87px;
      text-align: right;
    }
  }
  ul:nth-of-type(2) {
    margin-left: 20px;
    margin-right: 0px;
    padding-left: 0px;
  }
  @media screen and (max-width: 576px) {
    & {
      flex-direction: column;
    }

    ul:nth-of-type(2) {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 992px) {
    ul {
      margin-right: 0px;
    }
  }
}

.active {
  background-color: #183e6f;
  color: #fff;
  cursor: pointer;
  display: inline;
  margin-left: 0.3211rem;
  margin-right: 0.3211rem;
  a {
    background-color: #183e6f;
    padding: 0.3211rem 0.6rem;
  }
}

.paginationContainer {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  text-align: center;
  li {
    cursor: pointer;
    display: inline;
    margin-left: 0.3211rem;
    margin-right: 0.3211rem;
    a {
      padding: 0.3211rem 0.3rem;
    }
  }
}

.disabledPaginateControls {
  color: rgb(170, 170, 170);
  transition: 0.35s ease-out;
}

.nextLink {
  &::before {
    content: '\276F';
  }
}

.prevLink {
  &::before {
    content: '\276E';
  }
}
