.subscribe {
  padding-top: 1.25rem;
  margin-right: auto;
  max-width: 400px;
  * {
    color: #fff;
  }

  button {
    box-sizing: border-box;
    border: 1px solid #099bd9;
    border-radius: 38px;
    background-color: #099bd9;
    color: #fff;
    padding: 10px;
    font-size: 17px;
  }

  ul {
    text-align: left;
    list-style-type: disc;
    li {
      padding-left: 9px;
      margin-left: 9px;
      padding-top: 10px;
    }
  }

  p {
    padding-left: 18px;
    text-align: left;
  }

  h4 {
    padding: 0px;
    margin: 0px;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
